const drawerWidth = 240;

export const Styles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardheadercolor:{
    color:'white'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  maindropzoneArea: {
    padding: "5px 8px",
    backgroundColor: "#e3e2e2",
    border: "2px dashed #b7b7b7",
    borderRadius: "5px",
    fontSize: "16px",
    color: "#7d7c7b",
    cursor: "pointer",
  },
  dropzoneArea: {
    padding: "5px 8px",
    backgroundColor: "#70a1f0",
    border: "2px dashed #2B74E9",
    borderRadius: "5px",
    fontSize: "16px",
    color: "#000",
    cursor: "pointer",
  },
  dropzoneItem: {
    width: "fit-content",
    padding: "10px",
    backgroundColor: "#f4f4f4",
    border: "1px solid #CCC",
    borderRadius: "20px",
    marginBottom: "5px",
  },
  deleteItem: {
    height: "10px",
    fill: "#d35555",
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mb20: {
    marginBottom: "20px"
  },
  mb50: {
    marginBottom: "50px"
  },
  actionBox: {
    marginTop: "15px",
    backgroundColor: "#f0f0f0",
    boxShadow: "0 2px 4px rgba(27,31,35,.15)",
    "& div:first-child": {
      
      "& p": {
        fontWeight: "bold",
        margin: 0,
        padding: "6px 10px",
        textAlign: "left",
        "& span.type": {
          fontSize: "11px",
          fontWeight: "normal",
          fontStyle: "italic",
          marginLeft: "10px"
        },
        "& span.date": {
          fontSize: "10px",
          fontWeight: "normal",
          marginLeft: "10px"
        }
      }
    },
    "& div.privacyAction": {
      borderBottom: "1px solid #f50057",
    },
    "& div.publicAction": {
      borderBottom: "1px solid #3f51b5",
    },
    "& p": {
      margin: 0,
      padding: "10px",
      textAlign: "left !important"
    }
  },
  actionItems: {
    marginTop: "40px",
    width: "100%",
  },
  clientView: {
    width: "70%",
    margin: "0 auto",
    "& h2": {
      width: "100%",
      textAlign: "center"
    },
    "& p": {
      width: "100%",
      margin: 0,
      textAlign: "center"
    }
  },
  gridList: {
      width: "100%",
      height: 250,
  },
  attachmentsBox: {
    padding: "10px",
    border: "1px solid #CCC",
    borderRadius: "4px",
    marginBottom: "10px",
    color: "#919090",
    "& ul": {
      listStyle: "none",
      margin: 0,
      padding: 0,
      marginTop: "10px",
      "& li": {
        marginTop: "5px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "& svg": {
          fontSize: "18px",
        },
        "& a": {
          cursor: "pointer",
        }
      }
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
});
