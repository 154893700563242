import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'
import BasicSelect from '../select/BasicSelect'

export default function CustomOrganizationField({ organizationField, changeValue, stateName, label }) {
  
  const [text, setText] = useState("")
  const [radioItem, setRadioItem] = useState(0)
  const [selectItem, setSelectItem] = useState(0)
  const [checkboxItem, setCheckboxItem] = useState(false)

  const handleChange = (fieldType, id, value, stateName) => {

    if (fieldType === 1)
      setText(value)
    else if (fieldType === 2)
      setRadioItem(value)
    else if (fieldType === 3)
      setCheckboxItem(value)
    else if (fieldType === 4)
      setSelectItem(value)

    changeValue(fieldType, id, value, stateName)
  }

  switch (organizationField.organizationfieldtype)
  {
    case 1:
      return (<TextField
        name={`ofield-${organizationField.id}`}
        label={`${label && label.length > 0 ? label : organizationField.name}`}
        fullWidth
        value={text}
        onChange={(e) => handleChange(organizationField.organizationfieldtype, organizationField.id, e.target.value, stateName)}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: 255 }} />)
    case 2:
      return (<RadioGroup
        name={`ofield-${organizationField.id}`}
        value={radioItem}
        onChange={(e) => handleChange(organizationField.organizationfieldtype, organizationField.id, e.target.value, stateName)}>
        { organizationField.organizationfieldoptionsvalues && organizationField.organizationfieldoptionsvalues.map(item => {
          return <FormControlLabel key={item.id} value={item.id} control={<Radio size="small" />} label={item.name} />
        })}
        </RadioGroup>)
    case 3:
      return (<FormGroup>
        <FormControlLabel
          checked={checkboxItem}
          control={<Checkbox />}
          label={`${label && label.length > 0 ? label : organizationField.name}`}
          onChange={(e) => handleChange(organizationField.organizationfieldtype, organizationField.id, e.target.checked, stateName)} />
      </FormGroup>)
    case 4:
      let listOptions = organizationField.organizationfieldoptionsvalues && organizationField.organizationfieldoptionsvalues.map(item => {
        return { id: item.id, name: item.name }
      })

      return (<BasicSelect
        name={`ofield-${organizationField.id}`}
        label={`*${label && label.length > 0 ? label : organizationField.name}`}
        list={listOptions}
        value={selectItem}
        handleChange={(value) => handleChange(organizationField.organizationfieldtype, organizationField.id, value, stateName)} />)
    default:
      return (<div></div>)
  }
}