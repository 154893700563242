import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import { browserHistory } from './helpers/history';
import IntlProviderConfigured from './_i18n/intlproviderconfigured';
import './paginate.css';
import { useSelector } from "react-redux";
import { baseURL } from "./services/api";
import { Mexx2DOConsole } from './utils/mexxTalkConsole';
import axios from 'axios';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsexports from './config/aws-exports';

Amplify.configure(awsexports);

function App() {
    useEffect(() => {
      
    }, []);

    return (
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <Router history={browserHistory}>
                    <IntlProviderConfigured>
                        <Routes>
                    
                        </Routes>
                    </IntlProviderConfigured>                            
                </Router>
            </ThemeProvider>
        </PersistGate>

    );
}

export default App;