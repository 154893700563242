import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import Header from '../../components/header'
import Footer from '../../components/footer'
import LogoGovBr from '../../components/logo/logo-gov-br'
import './styles.css'

class AttendanceSuccess extends Component {
  constructor(props) {
    super(props)

    let { location } = this.props

    this.state = {
      calledId: location && location.state && location.state.calledId ? location.state.calledId : 0,
      createdAt: location && location.state && location.state.createdAt ? moment(location.state.createdAt).format('DD/MM/YYYY') : '',
      name: location && location.state && location.state.name ? location.state.name : '',
      email: location && location.state && location.state.email ? location.state.email : '',
      document: location && location.state && location.state.document ? location.state.document : '',
      ofieldsText: location && location.state && location.state.ofieldsText ? location.state.ofieldsText : '',
      backRoute: location && location.state && location.state.backRoute ? location.state.backRoute : '',
      loading: false,
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false
    }

    Mexx2DOConsole.log("=== AttendanceSuccess ===")
    Mexx2DOConsole.log(location && location.state && location.state.ofieldsText ? location.state.ofieldsText : '')

    this.closeNotification = this.closeNotification.bind(this)
  }

  

  closeNotification = _ => this.setState({ openNotification: false })

  render() {
    const { classes, intl } = this.props;

    return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Header />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: '#F8F8F8', padding: 50, minHeight: 'calc(100vh - 225px)' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={3} >
            <Grid container spaging={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LogoGovBr style={{ float: 'right', marginRight: '5%' }} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} />
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="body1" className={this.props.class} style={{ marginTop: 40, textAlign: 'right', marginRight: '5%', color: '#555', fontStyle: 'italic', fontWeight: 400 }} >
                  Atendimento ao usuário – Conta GOV.BR.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} style={{ width: '100%', boxSizing: 'border-box' }} >
            <Paper elevation={3} style={{ padding: '4% 5.6%', width: '100%', boxSizing: 'border-box', marginBottom: 30 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                  <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4' }}>
                    Solicitação enviada com sucesso!
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Número do protocolo:&nbsp;
                  </Typography>
                  <Typography variant="body1" component="span" className={this.props.class}>
                    {this.state.calledId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Data de criação:&nbsp;
                  </Typography>
                  <Typography variant="body1" component="span" className={this.props.class}>
                    {this.state.createdAt}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="fontFamilyInnerHTML" dangerouslySetInnerHTML={{__html: this.state.ofieldsText}} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Nome:&nbsp;
                  </Typography>
                  <Typography variant="body1" component="span" className={this.props.class} style={{ wordWrap: 'break-word' }}>
                    {this.state.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    E-mail:&nbsp;
                  </Typography>
                  <Typography variant="body1" component="span" className={this.props.class}>
                    {this.state.email}
                  </Typography>
                </Grid>
                {document.length == 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    CPF:&nbsp;
                  </Typography>
                  <Typography variant="body1" component="span" className={this.props.class}>
                    {this.state.document}
                  </Typography>
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                  <Paper elevation={3} >
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={2} md={2} lg={2} style={{ backgroundColor: '#1351b4' }}></Grid>
                      <Grid item xs={12} sm={10} md={10} lg={10} style={{ padding: '25px 25px 25px 20px' }}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                            IMPORTANTE:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" className={this.props.class}>
                            Você receberá um e-mail com o número de protocolo de atendimento. E as orientações serão enviadas para o mesmo e-mail no prazo de até 3 dias úteis.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => {this.props.history.push({ pathname: `/${this.props.match.params.lang}/${this.state.backRoute.length > 0 ? this.state.backRoute : ''}`})}}
                    variant="contained"
                    color="primary"
                    style={{ color: '#fff', padding: 14, textTransform: 'captalize!important' }} >
                    Enviar outra solicitação
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Footer />
      </Grid>
    </Grid>
    )
  }
}

AttendanceSuccess.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AttendanceSuccess)));