import React from 'react';
import Image from '../../images/face_recognition_near.jpg';

const FaceRecognitionNear = props => (
    <div style={ props.divStyle != null ? props.divStyle : {} } >
        <img
          src={Image}
          style={ props.style != null ? props.style : {} }
          title="Bom encaixe" />
    </div>
);

export default FaceRecognitionNear;