import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { isEmail } from '../../utils/mask/emailTestMask'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import { DeleteForever } from '@mui/icons-material'
import BasicSelect from '../../components/select/BasicSelect'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import { getConfigurationsById } from '../../services/organization.service'
import { getAllByParentOrganizationCategoryId } from '../../services/organizationcategor.service'
import { createClientExternalCalled } from '../../services/called.service'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { cpfMask, validateCpf, isCPFOrCNPJValid, CNPJMask } from '../../utils/cpfUtils'
import { randomIntFromInterval } from '../../utils/numberUtils'
import CustomOrganizationField from '../../components/custom-organization-field'
import CPFImage from '../../components/images/cpf'
import IdentidadeCPFImage from '../../components/images/identidade-cpf'
import { isInvalidFileType } from '../../utils/fileUtils'
import LogoGovBr from '../../components/logo/logo-gov-br'
import LogoSelfie from '../../components/logo/logo-selfie'
import './styles.css'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import FormHeader from '../../components/form-header'
import LogoSmall from '../../components/logo/logo-small'
import FormFooter from '../../components/form-footer'
import LogoCMTechSmall from '../../components/logo/logoCMTechSmall'

class Form extends Component {
  constructor(props) {
    super(props)

    const formId = this.props.match.params.id

    this.itemInitialState = {
      name: '',
      document: '',
      email: '',
      confirmEmail: '',
      phone: '',
      title: '',
      description: '',
      category: 0,
      hirerId: 0,
      organizationId: 0,
      rootOrganizationCategoryId: 0,
      organizationCategoryId: 0,
      typeId: 0,
      subjectId: 0,
      subjectValue: 0,
      reasonId: 0,
      reasonValue: 0,
      serviceId: 0,
      serviceValue: 0,
      firstNumber: '',
    }

    this.state = {
      formId: formId,
      apiError: false,
      loading: false,
      item: this.itemInitialState,
      itemError: {
        title: '',
        description: '',
        category: false,
        name: '',
        document: '',
        email: '',
        confirmEmail: '',
        phone: '',
        rootOrganizationCategoryId: false,
        organizationCategoryId: false,
        typeId: false,
        file: false,
        selfieFiles: false,
        selfieFilesText: "O campo 'Anexos' é obrigatório",
        documentFiles: false,
        firstNumber: '',
        showFooter : false
      },
      sumChapta: {
        firstNumber: 0,
        secondNumber: 0,
        sum: 0
      },
      enablecaptcha: false,
      issetTitle: false,
      issetDescription: false,
      issetCategory: false,
      issetDocument: false,
      issetPhone: false,
      formTitle: 'Como podemos ajudar?',
      nameFieldLabel: 'Nome',
      documentFieldLabel: 'CPF',
      emailFieldLabel: 'Confirmar E-mail',
      confirmEmailFieldLabel: 'Confirmar E-mail',
      filesUpload: {},
      CalledServiceSettingsId: 0,
      CalledServiceSettingsCalledFields: [],
      CalledServiceSettingsOrganizationFields: [],
      CalledServiceSettingsFileUploads: [],
      subject: { organizationfieldtype: 5 },
      reason: { organizationfieldtype: 5 },
      service: { organizationfieldtype: 5 },
      selfieFiles: [],
      documentFiles: [],
      files: [],
      pageloaded: false,
      rootOrganizationCategoryList: [],
      subOrganizationCategoryList: [],
      typeList: [],
      categoryList: [],
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false
    }

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
    
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.getConfigurationsById = this.getConfigurationsById.bind(this)
    this.getAllByParentOrganizationCategoryId = this.getAllByParentOrganizationCategoryId.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeEditor = this.handleChangeEditor.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.compareEmails = this.compareEmails.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.removeItems = this.removeItems.bind(this)
    this.removeSelfFilesItems = this.removeSelfFilesItems.bind(this)
    this.removeDocumentFilesItems = this.removeDocumentFilesItems.bind(this)
    this.removeFilesUploadItems = this.removeFilesUploadItems.bind(this)
    this.notEventChange = this.notEventChange.bind(this)
    this.formatOptions = this.formatOptions.bind(this)
    this.validateNotificationMessageType = this.validateNotificationMessageType.bind(this)
    this.validateFiles = this.validateFiles.bind(this)
    this.generateSumCaptcha = this.generateSumCaptcha.bind(this)
  }

  closeNotification = _ => this.setState({ openNotification: false })

  async componentDidMount() {
    if (Api === null || typeof Api === 'undefined' || this.state.formId == null || (this.state.formId != null && this.state.formId == 0)) {
      this.setState({ apiError: true })
    } else {
      this.setState({ pageloaded:false })
      await this.getConfigurationsById(this.state.formId)
      this.generateSumCaptcha()
      this.setState({ pageloaded: true })
    }
  }

  generateSumCaptcha = () => {
    let firstNumber = Math.floor(Math.random() * 10) + 1
    let secondNumber = Math.floor(Math.random() * 10) + 1
    let sum = firstNumber + secondNumber

    this.setState({
      sumChapta: {
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        sum: sum
      }
    })
  }

  getConfigurationsById = async (id) => {
    this.setState({ loading: true })

    let response = await getConfigurationsById(id)
    if (response && response.success) {
      if (response.data != null) {
        console.log(response)
        console.log("response")
        let orgData = {
          id: 0,
          hirerId: 0,
          typeList: [],
          rootOrganizationCategoryList: [],
          subjectId: 0,
          subject: { organizationfieldtype: 5 },
          reasonId: 0,
          reason: { organizationfieldtype: 5 },
          serviceId: 0,
          service: { organizationfieldtype: 5 },
        }

        if (response.data.id != null)
          orgData.id = response.data.id
        if (response.data.hirerid != null)
          orgData.hirerId = response.data.hirerid
        if (response.data.subject && response.data.subject.id > 0) {
          orgData.subjectId = response.data.subject.id
          orgData.subject = response.data.subject
        }
        if (response.data.reason && response.data.reason.id > 0) {
          orgData.reasonId = response.data.reason.id
          orgData.reason = response.data.reason
        }
        if (response.data.service && response.data.service.id > 0) {
          orgData.serviceId = response.data.service.id
          orgData.service = response.data.service
        }

        if (response.data.organizationcategories && response.data.organizationcategories.length > 0)
          this.setState({ categoryList: response.data.organizationcategories })

        if (response.data.calledservicesettingscalledfields && response.data.calledservicesettingscalledfields.length > 0) {
          response.data.calledservicesettingscalledfields.forEach(item => {
            if (item != null) {
              if (item.fieldtype == 1)
                this.setState({ issetTitle: true })
              else if (item.fieldtype == 2)
                this.setState({ issetDescription: true })
              else if (item.fieldtype == 3)
                this.setState({ issetCategory: true })
              else if (item.fieldtype == 6)
                this.setState({ issetPhone: true })
              else if (item.fieldtype == 7)
                this.setState({ issetDocument: true })

              if (item.fieldtype == 4 && item.label.length > 0)
                this.setState({ nameFieldLabel: item.label })
              else if (item.fieldtype == 5 && item.label.length > 0) {
                this.setState({ emailFieldLabel: item.label })
                this.setState({ confirmEmailFieldLabel: `Confirmar ${item.label}` })
              } else if (item.fieldtype == 7 && item.label.length > 0)
                this.setState({ documentFieldLabel: item.label })
            }
          })
        } else {
          this.setState({ issetDocument: true })
        }

        if (response.data.calledservicesettingsorganizationfields && response.data.calledservicesettingsorganizationfields.length > 0) {
          Mexx2DOConsole.log("=== response.data.calledservicesettingsorganizationfields ===")
          Mexx2DOConsole.log(response.data.calledservicesettingsorganizationfields)
          Mexx2DOConsole.log(typeof response.data.calledservicesettingsorganizationfields)
          response.data.calledservicesettingsorganizationfields.forEach(item => {
            let initValue = (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype !== 1) ? (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype === 3) ? false : null : ""

            this.setState(prevState => ({
              item: {
                ...prevState.item,
                [`ofield-${item.organizationfield.id}`]: initValue
              },
              itemError: {
                ...prevState.itemError,
                [`ofield-${item.organizationfield.id}`]: false,
                [`ofield-${item.organizationfield.id}-text`]: ''
              }}))
          })
        }
        if (response.data.calledservicesettingsfileuploads && response.data.calledservicesettingsfileuploads.length > 0) {
          response.data.calledservicesettingsfileuploads.forEach(item => {
            this.setState(prevState => ({
              filesUpload: {
                ...prevState.filesUpload,
                [`files-${item.id}`]: []
              }}))
          })
        }

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            organizationId: orgData.id,
            hirerId: orgData.hirerId,
            subjectId: orgData.subjectId,
            reasonId: orgData.reasonId,
            serviceId: orgData.serviceId,
          },
          enablecaptcha: response.data.enablecaptcha != null ? response.data.enablecaptcha : false,
          formTitle: response.data.title && response.data.title.length > 0 ? response.data.title : this.state.formTitle,
          CalledServiceSettingsId: response.data.calledservicesettingsid,
          CalledServiceSettingsCalledFields: response.data.calledservicesettingscalledfields && response.data.calledservicesettingscalledfields.length > 0 ? response.data.calledservicesettingscalledfields.sort((a, b) => a.order - b.order) : [],
          CalledServiceSettingsOrganizationFields: response.data.calledservicesettingsorganizationfields && response.data.calledservicesettingsorganizationfields.length > 0 ? response.data.calledservicesettingsorganizationfields.sort((a, b) => a.order - b.order) : [],
          CalledServiceSettingsFileUploads: response.data.calledservicesettingsfileuploads && response.data.calledservicesettingsfileuploads.length > 0 ? response.data.calledservicesettingsfileuploads.sort((a, b) => a.order - b.order) : [],
          subject: orgData.subject && orgData.subject.organizationfieldoptionsvalues && orgData.subject.organizationfieldoptionsvalues.length > 0 && orgData.subject.organizationfieldtype && orgData.subject.organizationfieldtype === 4 ? this.formatOptions(orgData.subject) : orgData.subject,
          reason: orgData.reason && orgData.reason.organizationfieldoptionsvalues && orgData.reason.organizationfieldoptionsvalues.length > 0 && orgData.reason.organizationfieldtype && orgData.reason.organizationfieldtype === 4 ? this.formatOptions(orgData.reason) : orgData.reason,
          service: orgData.service && orgData.service.organizationfieldoptionsvalues && orgData.service.organizationfieldoptionsvalues.length > 0 && orgData.service.organizationfieldtype && orgData.service.organizationfieldtype === 4 ? this.formatOptions(orgData.service) : orgData.service
        }))
      }
      this.setState({ loading: false })
    } else {
      this.setState({
        loading: false,
        apiError: true
      })
    }

    this.setState({ showFooter : true })
  }

  formatOptions = (organizationField) => {
    if (organizationField.organizationfieldoptionsvalues && organizationField.organizationfieldoptionsvalues.length > 0) {
      organizationField.organizationfieldoptionsvalues = organizationField.organizationfieldoptionsvalues.sort((a, b) => a.id - b.id)
    }
    return organizationField
  }

  getAllByParentOrganizationCategoryId = async (id) => {
    this.setState({ loading: true })
    let response = await getAllByParentOrganizationCategoryId(id)
    if (response && response.success) {
      if (response.data != null) {
        if (response.data && response.data.length > 0)
          this.setState({ subOrganizationCategoryList: response.data })
      }
      this.setState({ loading: false })
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"
      })
    }
  }

  removeItems = () => {
    this.setState({ files: [] })
  }

  removeSelfFilesItems = () => {
    this.setState({ selfieFiles: [] })
  }

  removeFilesUploadItems = (stateName) => {
    this.setState(prevState => ({ filesUpload: { ...prevState.filesUpload, [stateName]: [] } }))
  }

  removeDocumentFilesItems = () => {
    this.setState({ documentFiles: [] })
  }

  removeItem = (file) => {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  compareEmails = value => {
    value !== this.state.item.email ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
  }

  handleChange = (event) => {
    let { name, value } = event.target

    if (name === 'name') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    } else if (name === 'title') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, title: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, title: '' } }))
    } else if (name === 'phone') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, phone: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, phone: '' } }))
    } else if (name === 'document') {
      
      if(value.length <= 14){
        value = cpfMask(value)
      }
      else{
        value = CNPJMask(value)
      }
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } })) : ((value.length !== 14 && value.length !== 18) || !isCPFOrCNPJValid(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    } else if (name === 'email') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } })) : !isEmail(value) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
      this.compareEmails(value)
    } else if (name === 'confirmEmail') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } })) : value !== this.state.item.email ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    } else if (name === 'rootOrganizationCategoryId') {
      if (value > 0) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, rootOrganizationCategoryId: false } }))
        this.getAllByParentOrganizationCategoryId(value);
      }
    } else if (name === 'organizationCategoryId') {
      if (value > 0)
        this.setState(prevState => ({ itemError: { ...prevState.itemError, organizationCategoryId: false } }))
    }

    if (name === 'firstNumber') {
      const regex = /^[0-9\b]+$/;

      !regex.test(value) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: '' } }))

      if (value === '' || regex.test(value))
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
    } else if (name !== 'name' || (name === 'name' && value.length <= 255))
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
  }

  convertHtmlOption = value => {
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let sanitized = temp.textContent || temp.innerText

    return sanitized
  }

  handleChangeEditor = (value) => {
    
    let sanitizedText = this.convertHtmlOption(value)

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        description: value || ""
      },
      itemError: {
        ...prevState.itemError,
        description: (sanitizedText == null || (sanitizedText && sanitizedText.length > 0)) ? '' : 'Campo obrigatório' }
    }))
  }

  handleChangeSelect = (value) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        category: value
      },
      itemError: {
        ...prevState.itemError,
        category: value > 0 ? false : true
      }
    }))
  }

  onChangeCustomField = (fieldType, id, value, stateName) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: (fieldType != 1 && fieldType != 3) ? parseInt(value) : value
      }
    }))

    let itemData = this.state.CalledServiceSettingsOrganizationFields.find(item => item.organizationfieldid == id)

    if (fieldType != 1 && fieldType != 3) {
      let parseValue = parseInt(value)
      if (itemData != null) {
        if (itemData.isrequired) {
          if (parseValue > 0) {
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${id}`]: false } }))
          }
        }

        if (itemData.calledservicesettingsorganizationfieldvalidations && itemData.calledservicesettingsorganizationfieldvalidations.length > 0) {
          if (parseValue == itemData.calledservicesettingsorganizationfieldvalidations[0].organizationfieldoptionsvaluesid) {
            this.setState({
              notificationVariant: this.validateNotificationMessageType(itemData.calledservicesettingsorganizationfieldvalidations[0].messagetype),
              notificationMessage: itemData.calledservicesettingsorganizationfieldvalidations[0].message,
              openNotification: true
            })
          }
        }
      }
    } else {
      if (itemData && itemData.isrequired) {
        if (value && value.length > 0) {
          this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${id}`]: false } }))
        }
      }
    }
    Mexx2DOConsole.log("=== onChangeCustomField ===")
    Mexx2DOConsole.log(this.state.item)
  }

  validateNotificationMessageType = (messageType) => {
    switch(messageType) {
      case 2:
        return 'info'
      case 3:
        return 'warning'
      case 4:
        return 'error'
      case 1:
      default:
        return 'success'
    }
  }

  validateFiles = (itemId, acceptedFiles, fileLimit) => {
    Mexx2DOConsole.log("=== validateFiles ===")
    Mexx2DOConsole.log(acceptedFiles)
    Mexx2DOConsole.log(itemId)
    Mexx2DOConsole.log(fileLimit)
    if (acceptedFiles && acceptedFiles.length > fileLimit) {
      this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${itemId}`]: true, [`files-${itemId}-text`]: `Você precisa inserir no máximo ${fileLimit} anexos` } }), () => {
        Mexx2DOConsole.log("=== PrevState -> itemError ===")
        Mexx2DOConsole.log(this.state.itemError)
      })
    } else {
      this.setState(prevState => ({ filesUpload: { ...prevState.filesUpload, [`files-${itemId}`]: [...acceptedFiles] } }), () => {
        Mexx2DOConsole.log("=== PrevState -> filesUpload ===")
        Mexx2DOConsole.log(this.state.filesUpload)
      })
    }
  }

  validateForm = () => {
    let { item, itemError } = this.state
    let hasError = false

    let erros = {
      title: false,
      description: false,
      category: false,
      name: false,
      document: false,
      email: false,
      confirmEmail: false,
      phone: false,
      rootOrganizationCategoryId: false,
      organizationCategoryId: false,
      typeId: false,
      file: false,
      selfieFiles: false,
      selfieFilesText: "O campo 'Anexos' é obrigatório",
      documentFiles: false,
      firstNumber: false
    }

    // valida título
    if (this.state.issetTitle && item.title.length === 0) {
      erros.title = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, title: 'Campo obrigatório' } }))
    } else {
      erros.title = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, title: '' } }))
    }

    // valida telefone
    if (this.state.issetPhone && item.phone.length === 0) {
      erros.phone = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, phone: 'Campo obrigatório' } }))
    } else {
      erros.phone = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, phone: '' } }))
    }

    // valida descrição
    if (this.state.issetDescription) {
      let sanitizedDescription = this.convertHtmlOption(item.description)
      if (sanitizedDescription == null || (sanitizedDescription != null && sanitizedDescription.length === 0)) {
        erros.description = true
        this.setState(prevState => ({ itemError: { ...prevState.itemError, description: 'Campo obrigatório' } }))
      } else {
        erros.description = false
        this.setState(prevState => ({ itemError: { ...prevState.itemError, description: '' } }))
      }
    }

    // valida categoria
    if (this.state.issetCategory && (item.category == null || (item.category != null && item.category === 0))) {
      erros.category = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, category: true } }))
    } else {
      erros.category = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, category: false } }))
    }

    // valida nome
    if (item.name.length === 0) {
      erros.name = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } }))
    } else {
      erros.name = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    }
    
    // valida CPF
    //item.document = cpfMask(item.document)
    console.log('document ===')
    console.log(item.document)
    console.log(this.state.issetDocument)
    if (this.state.issetDocument && item.document.length === 0) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } }))
    } else if (this.state.issetDocument && ( (item.document.length !== 14 && item.document.length !== 18) || !isCPFOrCNPJValid(item.document))) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } }))
    } else {
      erros.document = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    }
    
    // valida Email
    if (item.email.length === 0) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } }))
    } else if (!isEmail(item.email)) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } }))
    } else {
      erros.email = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
    }
    
    // valida Confirmação de Email
    if (item.confirmEmail.length === 0) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } }))
    } else if (item.confirmEmail !== this.state.item.email) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } }))
    } else {
      erros.confirmEmail = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    }

    this.state.CalledServiceSettingsOrganizationFields.forEach(item => {
      if (item.isrequired) {
        let itemData = this.state.item[`ofield-${item.organizationfield.id}`]
        let validation = `O campo '${item.label}' é obrigatório`
        if (item.requiredfieldmessage && item.requiredfieldmessage != null)
          validation = item.requiredfieldmessage
        
        if (item.organizationfield && item.organizationfield.organizationfieldtype && (item.organizationfield.organizationfieldtype !== 1 && item.organizationfield.organizationfieldtype !== 3)) {
          if (itemData && itemData > 0) {
            if (item.calledservicesettingsorganizationfieldvalidations && item.calledservicesettingsorganizationfieldvalidations.length > 0) {
              if (item.calledservicesettingsorganizationfieldvalidations[0] && item.calledservicesettingsorganizationfieldvalidations[0].messagetype && item.calledservicesettingsorganizationfieldvalidations[0].messagetype == 4 && parseInt(itemData) == item.calledservicesettingsorganizationfieldvalidations[0].organizationfieldoptionsvaluesid) {
                erros[`ofield-${item.organizationfield.id}`] = true
                this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: item.calledservicesettingsorganizationfieldvalidations[0].message } }))
              } else {
                erros[`ofield-${item.organizationfield.id}`] = false
                this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
              }
            } else {
              erros[`ofield-${item.organizationfield.id}`] = false
              this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
            }
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        } else if (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype === 3) {
          if (itemData && itemData.length > 0 && (itemData == true || itemData == false)) {
            erros[`ofield-${item.organizationfield.id}`] = false
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        } else {
          if (itemData && itemData.length > 0) {
            erros[`ofield-${item.organizationfield.id}`] = false
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        }
      }
    })

    this.state.CalledServiceSettingsCalledFields.forEach(item => {
      
    })

    this.state.CalledServiceSettingsFileUploads.forEach(item => {
      let itemData = this.state.filesUpload[`files-${item.id}`]
      if (item.isrequired && (itemData == null || typeof itemData === 'undefined' || (itemData && itemData.length == 0))) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: true, [`files-${item.id}-text`]: `O campo '${item.label}' é obrigatório` } }))
        erros[`files-${item.id}`] = true
      } else if (itemData.length > item.filelimit) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: true, [`files-${item.id}-text`]: `Você precisa inserir no máximo ${item.filelimit} anexos` } }))
        erros[`files-${item.id}`] = true
      }
    })

    // valida CAPTCHA
    if (this.state.enablecaptcha) {
      if(item.firstNumber.length === 0) {
        erros.firstNumber = true
        this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } }))
      } else if(item.firstNumber != this.state.sumChapta.firstNumber) {
        erros.firstNumber = true
        this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'CAPTCHA inválido' } }))
      } else if (item.firstNumber == this.state.sumChapta.firstNumber) {
        erros.firstNumber = false
        this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: '' } }))
      } else {
        erros.firstNumber = true
        this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } }))
      }
    }

    Object.values(erros).forEach(val => {
      if (val == true)
        hasError = true
    });

    return hasError
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true })

    let validateForm = await this.validateForm()
    if (validateForm === false) {
      let { item } = this.state

      console.log(item.phone)
      console.log("item.phone")
      
      let { name, email, document } = this.state.item
      
      let organizationFieldIds = []
      let organizationFieldValues = []
      this.state.CalledServiceSettingsOrganizationFields.forEach(item => {
        let valueData = ''

        organizationFieldIds.push(item.organizationfieldid)
        if (this.state.item[`ofield-${item.organizationfieldid}`] != null && typeof this.state.item[`ofield-${item.organizationfieldid}`] !== 'undefined') {
          organizationFieldValues.push(this.state.item[`ofield-${item.organizationfieldid}`])
        } else {
          organizationFieldValues.push('')
        }

        if (item.organizationfield && item.organizationfield.organizationfieldtype !== 1 && item.organizationfield.organizationfieldoptionsvalues != null) {
          let valueDataOptionItem = item.organizationfield.organizationfieldoptionsvalues.find(item => item.id == this.state.item[`ofield-${item.organizationfieldid}`])
          if (valueDataOptionItem != null)
            valueData = valueDataOptionItem.name
        } else
          valueData = this.state.item[`ofield-${item.organizationfieldid}`]
      })

      let description = item.description

      let data = new FormData()
      data.append('calledservicesettingsid', this.state.CalledServiceSettingsId)
      data.append('route', 'alterar-email-na-conta')
      data.append('username', item.name)
      data.append('useremail', item.email)
      data.append('userdocument', item.document.length == 0 ? ' ' : item.document)
      data.append('hirerid', item.hirerId)
      data.append('organizationfieldids', organizationFieldIds.join())
      data.append('organizationfieldvalues', organizationFieldValues.join())
      data.append('organizationid', item.organizationId)
      data.append('description', description)
      data.append('validateuserpeplist', true)
      data.append('validateuserblacklist', true)
      if (this.state.issetCategory && item.category > 0)
        data.append('organizationcategoryid', item.category)
      if (this.state.issetTitle && item.title.length > 0)
        data.append('title', item.title)
      if (this.state.issetPhone && item.phone.length > 0)
        data.append('userphone', item.phone)

      let files = []
      Object.values(this.state.filesUpload).forEach(item => {
        if (item && item.length > 0)
          files.push(...item)
      })

      let bigFile = []
      let invalidFormatFile = []
      let fileMaxSize = 1024 * 50 // 50 MB

      files.forEach(file => {
        Mexx2DOConsole.log("=== files.forEach ===")
        Mexx2DOConsole.log(file)
        Mexx2DOConsole.log(isInvalidFileType(file.type))

        if (isInvalidFileType(file.type))
          invalidFormatFile.push(file.name)

        let fileKbSize = Math.round((file.size / 1024))
        if (fileKbSize > fileMaxSize)
          bigFile.push(file.name)

        let randomCode = randomIntFromInterval(100, 10000)
        let renamedFile = new File([file], `${randomCode}_${file.name}`)

        data.append('filesUpload', renamedFile)
      });

      Mexx2DOConsole.log("=== handleSubmit ===")
      Mexx2DOConsole.log(item)
      Mexx2DOConsole.log(this.state.files)
      Mexx2DOConsole.log(bigFile)
      Mexx2DOConsole.log(bigFile.length)
      Mexx2DOConsole.log(invalidFormatFile)
      Mexx2DOConsole.log(invalidFormatFile.length)

      if (bigFile.length == 0 && invalidFormatFile.length == 0) {
        const formId = this.props.match.params.id

        let response = await createClientExternalCalled(data)
        if (response && response.success) {
          
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Solicitação enviada com sucesso',
            item: this.itemInitialState,
            filesUpload: {},
            subOrganizationCategoryList: [],
            files: []
          })
          if (response.data != null) {
            this.props.history.push({
              pathname: `/${this.props.match.params.lang}/form-send-success`,
              state: {
                calledId: response.data.id,
                createdAt: response.data.createdat,
                name,
                email,
                document,
                backRoute: `form/${formId}`
              }
            })
          }
        } else {

          if (response && response.errors && response.errors[0]) {
            let error = response.errors[0]
            if (error === 'Validation_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/form-send-error-validation`,
                state: {
                  errorMessage: response.data != null && response.data.length > 0 ? response.data : '',
                  backRoute: `form/${formId}`
                }
              })
            } else if (error === 'Called_Exists') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/form-send-error-exists`,
                state: {
                  name,
                  email,
                  document,
                  calledId: response.data && response.data.id ? response.data.calledid : 0,
                  fixedMessage: response.data && response.data.fixedmessage ? response.data.fixedmessage : 0,
                  message: response.data && response.data.message ? response.data.message : 0,
                  backRoute: `form/${formId}`
                }
              })
            }
          }

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"
          })
        }
      } else {
        let errorMessage = ''

        if (bigFile.length > 0)
          errorMessage = 'Você não pode enviar arquivos com o tamanho maior que 50MB'
        if (bigFile.length > 0 && invalidFormatFile.length > 0)
          errorMessage += '\n'
        if (invalidFormatFile.length > 0) {
          errorMessage += 'São aceitos somente arquivos no formato: \n'
          errorMessage += 'Imagens - BMP, GIF, JPG, JPEG, PNG e TIFF; \n'
          errorMessage += 'Documentos DOC, DOCX, ODP, ODS, ODT, PDF, PPT, PPTX e RTF; \n'
        }

        this.setState({
          loading: false,
          notificationVariant: 'error',
          notificationMessage: errorMessage,
          openNotification: true
        })
      }
    } else {
      this.setState({
        loading: false,
        notificationVariant: 'error',
        notificationMessage: 'Você precisa preencher os campos obrigatórios para enviar a solicitação',
        openNotification: true
      })
    }
  }

  notEventChange = (e) => {
    e.preventDefault()
  }

  render() {
    const { classes, intl } = this.props;
    const { item, itemError } = this.state

    return (
    <Grid container spacing={0}>
      <Grid container spacing={0} style={{ backgroundColor: '#F8F8F8', padding: '3% 15%', minHeight: 'calc(100vh - 225px)' }}>
        <Grid container spacing={0}>
          { this.state.pageloaded === true && 
          <Grid item sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }} >
            <Paper elevation={3} style={{ padding: '4% 10%', width: '100%', boxSizing: 'border-box' }}>
              { this.state.apiError === false && <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                  <Typography variant="h2" className={this.props.class} style={{ color: '#3f51b4' }}>
                    {this.state.formTitle}
                  </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }}>
                  <form name="myForm" onSubmit={this.handleSubmit}>  
                    <Grid container spacing={2}>
                      {this.state.CalledServiceSettingsOrganizationFields.map(item => {
                        let attachmentList = item.calledservicesettingsorganizationfieldattachments && item.calledservicesettingsorganizationfieldattachments.length > 0 ? item.calledservicesettingsorganizationfieldattachments : []

                        if(item.organizationfield.organizationfieldtype == 4) {
                          if (item.organizationfield.organizationfieldoptionsvalues != null)
                          item.organizationfield.organizationfieldoptionsvalues = item.organizationfield.organizationfieldoptionsvalues.sort((a, b) => a.name - b.name)
                        }
                        
                        return (
                          <Grid key={item.id} item sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                              { ((item.organizationfield.organizationfieldtype === 2) || attachmentList.length > 0) && <Grid item sm={12} md={12} lg={12}>
                                <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                  {item.label}{item.isrequired && "*"}
                                </Typography>
                                { (item.organizationfield.organizationfieldtype === 2 && itemError[`ofield-${item.organizationfield.id}`]) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`ofield-${item.organizationfield.id}-text`]}</p>}
                              </Grid>}
                              { attachmentList.map(attachmentItem => {
                                return (
                                  <Grid item sm={6} md={6} lg={6}>
                                    <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                                  </Grid>
                                )
                              }) }
                              <Grid item sm={12} md={12} lg={12}>
                                <CustomOrganizationField
                                  organizationField={item.organizationfield}
                                  label={item.label}
                                  changeValue={this.onChangeCustomField}
                                  stateName={`ofield-${item.organizationfield.id}`} />
                                { (item.organizationfield.organizationfieldtype !== 2 && itemError[`ofield-${item.organizationfield.id}`]) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`ofield-${item.organizationfield.id}-text`]}</p>}
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })
                      }
                      { this.state.CalledServiceSettingsCalledFields.map(item => {
                        if (item.fieldtype == 1) {
                          return (<Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                            <TextField
                              name="title"
                              label={`${item.label}*`}
                              fullWidth
                              value={this.state.item.title}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              error={itemError.title?.length > 0}
                              helperText={itemError.title}
                              inputProps={{ maxLength: 255 }} />
                          </Grid>)
                        } else if (item.fieldtype == 2) {
                          return (<Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                            <Typography variant="body1" className={this.props.class}>
                              {item.label}{item.isrequired && "*"}
                            </Typography>
                            <ReactQuill
                              name="description"
                              value={this.state.item.description || ''}
                              fullWidth
                              modules={this.reactQuillModules}
                              formats={this.reactQuillFormats}
                              required
                              InputLabelProps={{ shrink: this.state.item.description && this.state.item.description.length > 0 ? true : false }}
                              variant="outlined"
                              id="outlined-multiline-static"
                              onChange={this.handleChangeEditor} />
                              { (item.isrequired && itemError.description && itemError.description.length > 0) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError.description}</p>}
                          </Grid>)
                        } else if (item.fieldtype == 3) {
                          return (<Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                            <BasicSelect
                              name='category'
                              label={`${item.label && item.label.length > 0 ? item.label : 'Categoria'}${item.isrequired && '*'}`}
                              list={this.state.categoryList}
                              value={this.state.item.category}
                              handleChange={this.handleChangeSelect} />
                              { (item.isrequired && itemError.category) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>Campo obrigatório</p>}
                          </Grid>)
                        } else if (item.fieldtype == 4) {
                          return (<Grid item sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                            <TextField
                              name="name"
                              label={`${this.state.nameFieldLabel}*`}
                              fullWidth
                              value={this.state.item.name}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              error={itemError.name?.length > 0}
                              helperText={itemError.name}
                              inputProps={{ maxLength: 255 }} />
                          </Grid>)
                        } else if (item.fieldtype == 5) {
                          return (<Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                            <Grid container spacing={2}>
                              <Grid item sm={12} md={12} lg={6}>
                                <TextField
                                  name="email"
                                  label={`${this.state.emailFieldLabel}*`}
                                  fullWidth
                                  value={this.state.item.email}
                                  onChange={this.handleChange}
                                  onCut={this.notEventChange}
                                  onCopy={this.notEventChange}
                                  onPaste={this.notEventChange}
                                  InputLabelProps={{ shrink: true }}
                                  error={itemError.email?.length > 0}
                                  helperText={itemError.email} />
                              </Grid>
                              <Grid item sm={12} md={12} lg={6}>
                                <TextField
                                  name="confirmEmail"
                                  label={`${this.state.confirmEmailFieldLabel}*`}
                                  fullWidth
                                  autoComplete="new-password"
                                  value={this.state.item.confirmEmail}
                                  onChange={this.handleChange}
                                  onCut={this.notEventChange}
                                  onCopy={this.notEventChange}
                                  onPaste={this.notEventChange}
                                  InputLabelProps={{ shrink: true }}
                                  error={itemError.confirmEmail?.length > 0}
                                  helperText={itemError.confirmEmail} />
                              </Grid>
                            </Grid>
                          </Grid>)
                        } else if (item.fieldtype == 6) {
                          return (<Grid item sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                            <TextField
                              name="phone"
                              label={`${item.label}*`}
                              fullWidth
                              value={this.state.item.phone}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              error={itemError.phone?.length > 0}
                              helperText={itemError.phone}
                              inputProps={{ maxLength: 255 }} />
                          </Grid>)
                        } else if (item.fieldtype == 7) {
                          return (<Grid item sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                            <TextField
                              name="document"
                              label={`${this.state.documentFieldLabel}*`}
                              fullWidth
                              value={this.state.item.document}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }}
                              error={itemError.document?.length > 0}
                              helperText={itemError.document} />
                          </Grid>)
                        }
                      })}
                      { (this.state.CalledServiceSettingsCalledFields.length == 0 || !!this.state.CalledServiceSettingsCalledFields.find(e => e.fieldtype == 4) == false) &&
                        <Grid item sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="name"
                            label="Nome*"
                            fullWidth
                            value={item.name}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.name?.length > 0}
                            helperText={itemError.name}
                            inputProps={{ maxLength: 255 }} />
                        </Grid>
                      }
                      { (this.state.CalledServiceSettingsCalledFields.length == 0) && 
                        <Grid item sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="document"
                            label="CPF/CNPJ*"
                            fullWidth
                            value={item.document}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.document?.length > 0}
                            helperText={itemError.document} />
                        </Grid>
                      }
                      { (this.state.CalledServiceSettingsCalledFields.length == 0 || !!this.state.CalledServiceSettingsCalledFields.find(e => e.fieldtype == 5) == false) && 
                        <Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                          <Grid container spacing={2}>
                            <Grid item sm={12} md={12} lg={6}>
                              <TextField
                                name="email"
                                label="E-mail*"
                                fullWidth
                                value={item.email}
                                onChange={this.handleChange}
                                onCut={this.notEventChange}
                                onCopy={this.notEventChange}
                                onPaste={this.notEventChange}
                                InputLabelProps={{ shrink: true }}
                                error={itemError.email?.length > 0}
                                helperText={itemError.email} />
                            </Grid>
                            <Grid item sm={12} md={12} lg={6}>
                              <TextField
                                name="confirmEmail"
                                label="Confirmar E-mail*"
                                fullWidth
                                autoComplete="new-password"
                                value={item.confirmEmail}
                                onChange={this.handleChange}
                                onCut={this.notEventChange}
                                onCopy={this.notEventChange}
                                onPaste={this.notEventChange}
                                InputLabelProps={{ shrink: true }}
                                error={itemError.confirmEmail?.length > 0}
                                helperText={itemError.confirmEmail} />
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                      {this.state.CalledServiceSettingsFileUploads.map(item => {
                        let attachmentList = item.calledservicesettingsfileuploadattachments && item.calledservicesettingsfileuploadattachments.length > 0 ? item.calledservicesettingsfileuploadattachments : []

                        return (
                          <Grid key={item.id} item sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                            <Grid container spacing={2}>
                              { (item.description && item.description.length > 0) && <Grid item sm={12} md={12} lg={12}>
                                <div  className="fontFamilyInnerHTML" style={{ fontFamily: "'Roboto','Helvetica','Arial',sans-serif!important" }} dangerouslySetInnerHTML={ {__html: item.description } }></div>
                              </Grid>}
                              {attachmentList.map(attachmentItem => {
                                return (
                                  <Grid item sm={6} md={6} lg={6}>
                                    <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                                  </Grid>
                                )
                              })}
                              <Grid item sm={12} md={12} lg={12}>
                                <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                  {item.label}{item.isrequired && "*"} {item.filelimit > 1 && `(Selecione no máximo ${item.filelimit} anexos)`}:
                                </Typography>
                                { itemError[`files-${item.id}`] && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`files-${item.id}-text`]}</p>}
                              </Grid>
                              <Grid item sm={12} md={6} lg={6} style={{ paddingTop: 15 }}>
                                <Grid container spacing={0}>
                                  <Grid item sm={10} md={11} lg={11}>
                                    <div className={classes.maindropzoneArea}>
                                    {item.filelimit > 1 ? 
                                    <Dropzone maxFiles={item.filelimit} onDrop={acceptedFiles => this.validateFiles(item.id, acceptedFiles, item.filelimit)}>
                                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          { this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                        </div>
                                      )}
                                    </Dropzone>
                                    :
                                    <Dropzone multiple={false} onDrop={acceptedFiles => {
                                      this.setState(prevState => ({
                                        filesUpload: {
                                          ...prevState.filesUpload,
                                          [`files-${item.id}`]: acceptedFiles
                                        }
                                      }))
                                    }}>
                                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          {this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                        </div>
                                      )}
                                    </Dropzone>}
                                    </div>
                                  </Grid>
                                  {this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length > 0 &&<Grid item sm={2} md={1} lg={1} onClick={() => { this.removeFilesUploadItems(`files-${item.id}`) }} style={{ backgroundColor: '#ef5350', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                    <DeleteForever className={classes.deleteItem} />
                                  </Grid>}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      {this.state.enablecaptcha && <Grid item sm={12} md={12} lg={10} style={{ paddingTop: 50 }} >
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          Complete a operação*
                        </Typography>
                      </Grid>}
                      {this.state.enablecaptcha && <Grid item sm={12} md={12} lg={2}  style={{ paddingTop: 50 }} />}
                      {this.state.enablecaptcha && <Grid item sm={12} md={12} lg={10}>
                        <Grid container spacing={0}>
                          <Grid item sm={2} md={2} lg={2}>
                            <TextField
                              name="firstNumber"
                              label=""
                              fullWidth
                              value={item.firstNumber}
                              onChange={this.handleChange}
                              error={itemError.firstNumber?.length > 0}
                              helperText={itemError.firstNumber}
                              InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item sm={10} md={10} lg={10} style={{ display: 'flex', alignItems: 'center', fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                              &nbsp;+ {this.state.sumChapta.secondNumber} = {this.state.sumChapta.sum}
                          </Grid>
                        </Grid>
                      </Grid>}
                      {this.state.enablecaptcha && <Grid item sm={12} md={12} lg={2} />}
                      <Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="mainprimary"
                          style={{ width: '15%', color: '#fff', padding: 14, textTransform: 'captalize!important' }} >
                          Enviar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>}
              { this.state.apiError === true && <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                  <Typography variant="h2" className={this.props.class} style={{ color: '#3f51b4' }}>
                    Formulário Temporariamente Indisponível
                  </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                  <Typography variant="body1" className={this.props.class}>
                    Equipe Mexx 2DO
                  </Typography>
                </Grid>
              </Grid> }
            </Paper>
          </Grid>
          }
        </Grid>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />
      </Grid>
      {this.state.showFooter && 
          <Grid item sm={12} md={12} lg={12} >
            <FormFooter />
          </Grid>
      }
      
    </Grid>
    )
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Form)));