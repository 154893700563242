import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  mainprimary: {
    contrastText: white,
    dark: '#3f51b4',
    main: '#3f51b4',
    light: '#3f51b4'
  },
  mainsecondary: {
    contrastText: white,
    dark: '#277afe',
    main: '#277afe',
    light: '#277afe'
  },
  primary: {
    contrastText: white,
    dark: '#0C326F',
    main: '#1351b4',
    light: '#2B74E9'
  },
  secondary: {
    contrastText: white,
    dark: '#41cf41',
    main: '#7adb70',
    light: '#bbeebb'
  },
  gray: {
    contrastText: black,
    dark: '#424242',
    main: '#525252',
    light: '#676767'
  },
  lightGray: {
    contrastText: black,
    dark: '#b6b6b6',
    main: '#E4E4E4',
    light: '#FCFCFC'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    title: white,
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  admin: {
    contrastText: white,
    dark: colors.grey[200],
    main: colors.grey[200],
    light: colors.grey[200]
  },
};
