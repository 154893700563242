import { Link } from '@mui/material';
import React from 'react';
import './styles.css'

function Footer() {
  return <div>
    <footer className='app-footer'>
      <div className='app-footer-content'>
        <div className='app-footer-info'>
          <Link style={{ textDecoration: 'none', color: '#337ab7', fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif', fontSize: 14, lineHeight: 1.42857143 }} href="https://www.gov.br/governodigital/pt-br/conta-gov-br/conta-gov-br/">Acesse para mais informações</Link>
        </div>
      </div>
    </footer>
    
  </div>;
}

export default Footer;